import { GET_ENTRY_INSPECT, GET_ENTRY_INSPECT_ERROR, GET_ENTRY_INSPECT_FULFILLED } from "redux/actions/entries";


const initialState = {
  winnerEntriesInspect: [],
  loading: false,
  error: null,
};

const entries = (state = initialState, { type, payload, err }) => {
  switch (type) {
    case GET_ENTRY_INSPECT:
      return { ...state, loading: true, error: null };

    case GET_ENTRY_INSPECT_FULFILLED:
      return {
        ...state,
        winnerEntriesInspect: payload.docs,
        loading: false,
        error: null,
      };

    case GET_ENTRY_INSPECT_ERROR:
      return { ...state, loading: false, error: err.message };

    default:
      return state;
  }
};

export default entries;