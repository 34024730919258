import requestApi from "utility/requestApi";

export const GET_ENTRY_INSPECT = "GET_ENTRY_INSPECT";
export const GET_ENTRY_INSPECT_FULFILLED = "GET_ENTRY_INSPECT_FULFILLED";
export const GET_ENTRY_INSPECT_ERROR = "GET_ENTRY_INSPECT_ERROR";

export const getEntryInspect = (entryId) => async (dispatch) => {
  dispatch({ type: GET_ENTRY_INSPECT });
  try {
    const { data } = await requestApi.get(`/promotion-entries/${entryId}/inspect?limit=1000`);
    dispatch({
      type: GET_ENTRY_INSPECT_FULFILLED,
      payload: data,
    });
  } catch (err) {
    dispatch({ type: GET_ENTRY_INSPECT_ERROR, err });
  }
};